import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/components/layout.js";
import { Box, Button } from "theme-ui";
import ContactHero from "../components/ContactHero";
import SEO from "../components/seo";
import { InlineWidget } from "react-calendly";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <SEO title="Book a Meeting" description="" mdxType="SEO" />
    <Box sx={{
      pt: ["2px", 3, 3],
      pb: [0, 0, 0],
      pl: [0, 5, "80px"],
      pr: [0, 5, "80px"]
    }} mdxType="Box">
      <InlineWidget pageSettings={{
        hideEventTypeDetails: false,
        hideLandingPageDetails: false
      }} styles={{
        height: '2300px'
      }} url="https://calendly.com/frankbarnhouse/discovery-call" mdxType="InlineWidget" />
    </Box>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      